<template>
  <!-- BEGIN: Content-->
  <div class="app-content content ">
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top">
            <div class="col-12">
              <h2 class="content-header-title mb-0">
                Memoirs profiles images
              </h2>
            </div>
          </div>
        </div>
      </div>
      <div class="content-body content-body--senior-call">
        <div class="row">
          <div class="col-12">

            <div class="card card--table">
              <div class="card-header d-flex align-items-center justify-content-between">
                <div>
                  <h4><strong>{{ dataTotal }}</strong> memoirs</h4>
                </div>
                <div>
                  <span
                    class="dis-ib"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Open filters"
                  ><a
                    class="btn btn-icon btn-icon rounded-circle btn-flat-secondary"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasFilters"
                    aria-controls="offcanvasFilters"
                    @click="openFilters()"
                  ><i data-feather="filter" /></a></span>
                  <!-- <a
                    href="#"
                    class="btn btn-icon btn-icon rounded-circle btn-flat-secondary"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Export to Excel"
                  ><i data-feather="file" /></a> -->
                </div>
              </div>
              <div
                id="usedFilters"
                class="card-body"
              >
                Applied Filters:
                <span
                  v-for="filter, index in watchFilters"
                  :key="index"
                  class="badge badge-light-dark ms-1"
                >
                  <template v-if="Array.isArray(filter)">
                    <template
                      v-for="str in filter"
                    >
                      {{ str.name }}
                      <a
                        :key="str.name"
                        class="ms-1 text-black"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Delete filter"
                        @click="deleteFilter(index)"
                      ><i data-feather="x" /></a>
                    </template>
                  </template>
                  <template v-else-if="typeof filter === 'object' && filter">
                    {{ filter.name }}
                    <a
                      class="ms-1 text-black"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Delete filter"
                      @click="deleteFilter(index)"
                    ><i data-feather="x" /></a>
                  </template>
                  <template v-else-if="filter">
                    {{ filter }}
                    <a
                      class="ms-1 text-black"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Delete filter"
                      @click="deleteFilter(index)"
                    ><i data-feather="x" /></a>
                  </template>
                </span>
              </div>

            </div>

            <div class="row">
              <div
                v-for="(memoirProfile, index) in data"
                :key="index"
                class="col-sm-4 col-lg-3"
              >
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title">
                      {{ memoirProfile.memoir.title }}
                    </h4>
                    <h6 class="card-subtitle text-muted">
                      {{ memoirProfile.memoir.year }}
                    </h6>
                    <div
                      v-if="memoirProfile.memoir.image"
                      class="content__portada"
                    >
                      <img
                        class="object-fit-cover img-fluid"
                        :src="memoirProfile.memoir.image.url"
                        :alt="memoirProfile.memoir.alt_text"
                      >
                    </div>

                    <p
                      v-if="memoirProfile.num_publications"
                      class="card-text"
                    >
                      <span :class="`badge bg-${memoirProfile.num_publications > 0 ? 'light-success' : 'light-danger'}`">{{ memoirProfile.num_publications }} publications</span>
                    </p>
                    <div class="d-flex justify-content-between">
                      <a
                        class="card-link text-warning bg-light-primary btn btn-icon rounded-circle"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasViewProfileMemoir"
                        aria-controls="offcanvasViewProfileMemoir"
                        :href="$router.resolve({ name: 'researcher.memoir-view.view', params: { id: memoirProfile.id }, query: { actAs: $route.query.actAs } }).href"
                        target="_blank"
                      ><span
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="View profile"
                      ><i data-feather="eye" /></span></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="d-flex align-items-center justify-content-between">
                <Paginator
                  :total="dataTotal"
                  :store="'memoirsProfile/filter'"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ConvoFilter
      :filter-path="'memoirs/filter'"
    >
      <template #filters>
        <!-- <div class="mb-1">
          <label
            for=""
            class="form-label"
          >Title</label>
          <input
            type="text"
            class="form-control"
            placeholder=""
            @change="saveFilter({ customLabel: true, name: `Title: $event.target.value`, value: $event.target.value }, 'title')"
          >
        </div> -->
        <div class="mb-1">
          <label
            for=""
            class="form-label"
          >Year</label>
          <date-picker
            v-model="filters.year"
            format="Y"
            type="year"
            value-type="format"
            class="w-100"
            @change="saveFilter({ customLabel: true, name: `Year: ${$event}`, value: $event }, 'year')"
          />
        </div>
      </template>
    </ConvoFilter>
  </div>
  <!-- END: Content-->
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import DatePicker from 'vue2-datepicker'
import Paginator from '@/views/back/partials/Paginator.vue'
import ConvoFilter from '../../../partials/offcanvas/ConvoFilter.vue'
import 'vue2-datepicker/index.css'

export default {
  components: {
    ConvoFilter,
    DatePicker,
    Paginator,
  },
  data() {
    return {
      routeResolve: {},
    }
  },
  computed: {
    ...mapGetters({
      filters: 'filters/filters',
      watchFilters: 'filters/watchFilters',
      data: 'memoirsProfile/items',
      dataTotal: 'memoirsProfile/itemsTotal',
      loggedUser: 'auth/admin',
    }),
  },
  async mounted() {
    await this.$store.dispatch('memoirsProfile/filter', this.filters)
    setTimeout(() => {
      // eslint-disable-next-line no-undef
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 500)
  },
  methods: {
    async showDeleteModal(id) {
      await this.$store.dispatch('modals/saveDeleteData', { name: 'memoirsProfile/fetch', url: `${Vue.prototype.$groupUrl}/memoirs-profiles/${id}` })
      this.$store.dispatch('modals/showDeleteModal', true)
    },
    saveFilter(value, field) {
      if (!value || (!value.value || (value.value === 'null' || (Array.isArray(value.value) && !value.value.length)))) {
        this.$store.dispatch('filters/deleteFilters', field)
      } else {
        this.$store.dispatch('filters/saveFilters', {
          value,
          field,
        })
      }
    },
    openFilters() {
      this.$store.dispatch('modals/toggleConvoFilters', true)
    },
    async deleteFilter(index) {
      this.$store.dispatch('filters/deleteFilters', index)

      this.$store.dispatch('filters/saveFilters', this.filters)
      await this.$store.dispatch('memoirsProfile/filter', this.filters)

      setTimeout(() => {
        // eslint-disable-next-line no-undef
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 250)
    },
  },
}
</script>
